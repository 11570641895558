<template>
  <div class="mobile-menu" @click="$emit('close')">
    <div @click.stop="" class="mobile-menu__wrapper">
      <div class="mobile-menu__top">
        <a href="/" class="logo"></a>
        <span @click="$emit('close')" class="mobile-menu__close"></span>
      </div>
      <div class="mobile-menu__content">
        <p>
          <a href="tel:+78003027713" class="mobile-menu__phone"
            >8 (800) 302 77 13</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import "./mobile-menu.scss";
import { mapGetters } from "vuex";

export default {
  name: "MobileMenu",
  data() {
    return {
      menu: [
        {
          name: "Как получить",
          anchor: "how-get",
        },
        {
          name: "Условия",
          anchor: "conditions",
        },
        {
          name: "Вопрос/ответ",
          anchor: "faq",
        },
      ],
      calculator: 10000,
    };
  },
  computed: {
    ...mapGetters({
      user: "application/user",
    }),
    isAuth() {
      return !!this.user.contactData?.phone;
    },
  },
  methods: {
    logout() {
      // @TODO реализовать
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  beforeCreate() {
    document.body.style.overflow = "hidden";
  },
};
</script>
